<script lang="ts" setup>

import { reactive, ref, onMounted } from 'vue'
import type { $Fetch } from 'ofetch'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import RoomieOtpInput from '@lahaus-roomie/roomie/src/components/RoomieOtpInput/index.vue'
import RoomiePhoneInput from '@lahaus-roomie/roomie/src/components/RoomiePhoneInput/index.vue'
import { STATUS as INPUT_STATUS } from '@lahaus-roomie/roomie/src/components/RoomieInput/index.vue'
import RoomieLoader from '@lahaus-roomie/roomie/src/components/RoomieLoader/index.vue'
import ChatIcon from '@lahaus-roomie/roomie/src/assets/icons/chat.svg'
import { loadCaptchaScript, getCaptchaToken } from './utils'
import type { Props, PhoneProperties } from './types'

import { useContactFormStore } from '@/stores/contactFormStore'
import { useUserStore } from '@/stores/userStore'

import AuthService from '@/services/authService'
import CdpService from '@/services/cdpService'
import { useThemeStore } from '@/stores/useThemeStore'

const themeStore = useThemeStore()

const { t, locale } = useI18n()
const { $config, $trackEvent } = useNuxtApp()

const userStore = useUserStore()

const cdpService = new CdpService({
  apiFetch: globalThis.$fetch as $Fetch,
  cdpUrl: $config.public.cdpApiUrl as string,
  xApiKey: $config.public.cdpApiKey as string,
  xApplicationId: $config.public.environment
})

const emit = defineEmits(['submit', 'sign-up'])

const contactFormStore = useContactFormStore()

const siteKey = $config.public.googleRecaptchaSiteKey as string

const otpValue = ref('')

const props = withDefaults(defineProps<Props>(), {
  listingIds: () => [],
  listingId: '',
  screen: '',
  initialStage: 'PHONE_FORM'
})

const formStage = ref(props.initialStage)

const form = reactive({
  phone: contactFormStore.userPhone,
  phone_number: '',
  phone_prefix: '',
  phone_is_valid: false
})

const title = t('title')

const authService = new AuthService({
  apiFetch: globalThis.$fetch as $Fetch,
  authUrl: $config.public.authorizationApiUrl as string,
  authId: $config.public.laHausAuthId as string,
  xApplicationId: $config.public.environment
})

const sendCodeFromExistingCustomer = async () => {
  form.phone_is_valid = true

  const phoneMatch = form.phone.match(/(\+\d+)(\d{10})/)

  if (phoneMatch) {
    form.phone_prefix = phoneMatch[1]
    form.phone_number = phoneMatch[2]

    if ($config.public.loginOtpDisabled) {
      return await handleFormSubmitValidate()
    }

    await sendOtpCode('SMS')
  } else {
    formStage.value = 'TECHNICAL_ERROR'
    sendSlackAlert('Login Form | No se pudo obtener el prefix y el number del phone', form)
  }
}

const handleFormSubmitSms = async () => {
  if ($config.public.loginOtpDisabled) {
    return await handleFormSubmitValidate()
  }

  formStage.value = 'LOADING_FORM'
  contactFormStore.updateUserPhone(form.phone, false)

  const exists = await isUserExisting(form.phone)

  if (!exists) return handleSignUpEvent()

  await sendOtpCode('SMS')
}

const handleFormSubmitValidate = async () => {
  formStage.value = 'LOADING_FORM'
  contactFormStore.updateUserPhone(form.phone, false)

  const userId = await getExistingUserId(form.phone)

  if (!userId) return handleSignUpEvent()

  // @ts-ignore
  window.dataLayer?.push({
    event: 'user-authentication-requested',
    screen: props.screen
  })

  $trackEvent({
    name: 'sso_user_authenticated',
    version: '2-0-0',
    data: {
      sent_at: new Date().toISOString(),
      client_id: $config.public.laHausAuthId,
      date_started: new Date().toISOString(),
      date_finished: new Date().toISOString(),
      verified: [{
        channel: 'PHONE',
        method: 'GOOGLE',
        value: 'PHONE_VALIDATED_WITHOUT_OTP'
      }]
    }
  })

  emit('submit', form, userId)
}

const getExistingUserId = async (phone: string) => {
  try {
    const user = await cdpService.getCustomerByPhone(phone, 1)
    return user.id
  } catch (error) {
    return ''
  }
}

const isUserExisting = async (phone: string) => {
  try {
    const user = await cdpService.getCustomerByPhone(phone, 1)
    return !!user.id
  } catch (error) {
    return false
  }
}

const sendSlackAlert = (title: string, extraPayload = {}) => {
  $fetch('/api/buyer-front/send-slack-alert', {
    method: 'POST',
    body: {
      title,
      content: {
        userPhone: form.phone,
        url: window.location.href,
        ...extraPayload
      }
    }
  })
}

const getPhoneProperties = (value:PhoneProperties) => {
  form.phone_number = value.phoneNumber
  form.phone_prefix = value.code
}

const otpButtonEnabled = ref(false)
const otpInputStaus = ref(INPUT_STATUS.default)
const otpInputStausMsg = ref('')

const otpResendTimer = ref(0)
const otpEnableResend = ref(true)

const timeUp = () => {
  otpEnableResend.value = true
}

const filledOtp = () => {
  otpButtonEnabled.value = true
  otpInputStaus.value = INPUT_STATUS.default
}

const handleOtpSubmit = async () => {
  try {
    formStage.value = 'LOADING_FORM'
    const captchaToken = await getCaptchaToken(siteKey)
    const result = await authService.challenge(otpValue.value, captchaToken)

    if (result?.auth_result) {
      const userId = userStore.setSession(result)

      // @ts-ignore
      window.dataLayer?.push({
        event: 'user-authentication-requested',
        screen: props.screen
      })

      emit('submit', form, userId)
    } else {
      formStage.value = 'TECHNICAL_ERROR'
      otpInputStaus.value = INPUT_STATUS.error
      sendSlackAlert('Login Form | Verificación de OTP no tiene tokens de acceso', result)
    }
  } catch (error: any) {
    otpInputStaus.value = INPUT_STATUS.error
    if (['invalid_code'].includes(error?.code)) {
      formStage.value = 'OTP_FORM'
      otpInputStausMsg.value = t(`errors.${error?.code}`)
    } else {
      formStage.value = 'TECHNICAL_ERROR'
      sendSlackAlert('Login Form | Verificación de OTP fallo', {
        error,
        message: error.message
      })
    }
  }
}

const resendOtpWhatsApp = async () => {
  otpResendTimer.value = 60
  otpEnableResend.value = false
  form.phone_is_valid = true

  const phoneMatch = form.phone.match(/(\+\d+)(\d{10})/)

  if (phoneMatch) {
    form.phone_prefix = phoneMatch[1]
    form.phone_number = phoneMatch[2]

    await sendOtpCode('WHATSAPP')
  } else {
    formStage.value = 'TECHNICAL_ERROR'
    sendSlackAlert('Login Form | No se pudo obtener el prefix y el number del phone', form)
  }
}

const resendOtpSms = async () => {
  otpResendTimer.value = 60
  otpEnableResend.value = false
  form.phone_is_valid = true

  const phoneMatch = form.phone.match(/(\+\d+)(\d{10})/)

  if (phoneMatch) {
    form.phone_prefix = phoneMatch[1]
    form.phone_number = phoneMatch[2]

    await sendOtpCode('SMS')
  } else {
    formStage.value = 'TECHNICAL_ERROR'
    sendSlackAlert('Login Form | No se pudo obtener el prefix y el number del phone', form)
  }
}

const handleSignUpEvent = () => {
  emit('sign-up', form)
}

const sendOtpCode = async (contactMedia = 'SMS') => {
  const isPhoneValid = form.phone_is_valid

  if (!isPhoneValid) {
    formStage.value = 'PHONE_FORM'
    return
  }

  formStage.value = 'LOADING_FORM'

  try {
    await authService.authorizerToken()

    const captchaToken = await getCaptchaToken(siteKey)

    const result = await authService.authorizer(form.phone_prefix, form.phone_number, captchaToken, contactMedia)

    if (result?.challenge && result?.challenge === 'confirm_otp') {
      formStage.value = 'OTP_FORM'
    } else {
      formStage.value = 'TECHNICAL_ERROR'
      sendSlackAlert('Login Form | El resultado del authorizer no fue confirm_otp', result)
    }
  } catch (error: any) {
    formStage.value = 'TECHNICAL_ERROR'
    sendSlackAlert('Login Form | Error enviando el OTP', {
      error,
      message: error.message
    })
  }
}

onMounted(() => {
  loadCaptchaScript(siteKey)
})
</script>

<script lang="ts">
export default {
  name: 'LoginForm'
}
</script>

<template>
  <div class="login-form">
    <template v-if="formStage === 'PHONE_FORM'">
      <h2
        v-sanitize.basic="title"
        class="hl-3-sb md:hl-2-sb mt-8 text-pine-600" />

      <form
        class="mt-24 flex flex-col"
        @submit.prevent="handleFormSubmitSms">
        <RoomiePhoneInput
          id="lead-form-phone"
          name="lead-form-phone"
          class="mb-16"
          :initial-country="locale"
          number-label="Número*"
          country-label="País"
          :is-required="true"
          :value="form.phone"
          @input="(newValue: string) => form.phone = newValue"
          @is-valid="(isValid: boolean) => form.phone_is_valid = isValid"
          @full="(value: PhoneProperties) => getPhoneProperties(value)" />

        <RoomieButton
          v-if="$config.public.loginOtpDisabled"
          :id="`${id}-submit-button-no-otp`"
          class="w-full gap-8 font-medium"
          variant="primary"
          :data-lh-id="`${id}-submit-button-no-otp`"
          type="submit"
          :aria-label="t('ctaNoOtp')">
          <span class="flex">
            {{ t('ctaNoOtp') }}
          </span>
        </RoomieButton>

        <RoomieButton
          v-else
          :id="`${id}-submit-button`"
          class="w-full gap-8 font-medium"
          :data-lh-id="`${id}-submit-button`"
          type="submit"
          :aria-label="t('cta')">
          <span class="flex">
            <ChatIcon class="h-24 w-24 fill-white mr-8" />
            {{ t('cta') }}
          </span>
        </RoomieButton>
      </form>

      <div class="flex flex-col mt-24 gap-8">
        <p
          :class="{
            'body-1-sb text-pine-600': themeStore.isV2,
            'text-carbon-800 font-medium': !themeStore.isV2
          }"
          class="text-center">
          {{ t('register') }}

          <a
            :id="`${id}-authentication-button`"
            :class="{
              'body-2-sb text-pine-600': themeStore.isV2,
              'font-medium text-primary-800': !themeStore.isV2
            }"
            class="ml-8 gap-8 underline"
            href="#"
            :data-lh-id="`${id}-authentication-button`"
            :aria-label="t('signIn')"
            @click="handleSignUpEvent">
            {{ t('registerCta') }}
          </a>
        </p>
      </div>
    </template>

    <div
      v-else-if="formStage === 'OTP_FORM'"
      class="flex flex-col gap-42">
      <div>
        <h2
          v-sanitize.basic="t('otpTitle')"
          class="text-18 font-medium mt-8 text-carbon-800" />

        <p
          v-sanitize.basic="t('otpDescription', { lastDigits: form.phone.slice(-2) })"
          class="text-14 text-carbon-300" />
      </div>

      <RoomieOtpInput
        id="roomie-input-otp"
        v-model="otpValue"
        :status="otpInputStaus"
        :helper-text="otpInputStausMsg"
        :time-limit="otpResendTimer"
        @time-up="timeUp"
        @filled="filledOtp" />

      <div
        v-if="otpEnableResend === true"
        class="flex flex-col gap-8">
        <p
          class="text-14 text-carbon-300">
          {{ t('otpResend') }}
        </p>

        <a
          data-lh-id="login-form-otp-resend-cta-sms"
          class="text-primary-600 underline cursor-pointer text-14"
          @click="resendOtpSms">
          {{ t('otpResendCtaSms') }}
        </a>

        <a
          data-lh-id="login-form-otp-resend-cta-whastapp"
          class="text-primary-600 underline cursor-pointer text-14"
          @click="resendOtpWhatsApp">
          {{ t('otpResendCtaWhastapp') }}
        </a>
      </div>

      <div v-else>
        <p class="text-14 text-carbon-300">
          {{ t('otpResend') }}
        </p>

        <p class="text-14">
          {{ t('otpResendWaiting') }}
        </p>
      </div>

      <div>
        <RoomieButton
          :id="`${id}-otp-button`"
          class="w-full font-medium"
          :data-lh-id="`${id}-otp-button`"
          :aria-label="t('otpCta')"
          :disabled="!otpButtonEnabled"
          @click="handleOtpSubmit">
          <span class="flex">
            {{ t('otpCta') }}
          </span>
        </RoomieButton>

        <a
          id="login-form-go-back-cta"
          class="block text-primary-600 underline cursor-pointer text-center mt-16"
          data-lh-id="login-form-go-back-cta"
          @click="formStage = 'PHONE_FORM'">
          {{ t('otpGoBackCta') }}
        </a>
      </div>
    </div>

    <div
      v-if="formStage === 'LOADING_FORM'"
      class="h-[337px] flex items-center justify-center">
      <RoomieLoader
        size="xl"
        color="primary" />
    </div>

    <div
      v-if="formStage === 'EXISTING_CUSTOMER_PHONE'"
      id="existing-customer-phone-form"
      class="flex flex-col items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="58"
        height="88"
        viewBox="0 0 58 58"
        fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.0001 57.3627C25.7392 57.3627 22.6484 55.945 20.5076 53.4922C19.8271 52.6982 18.7496 52.2729 17.7004 52.3296C14.4395 52.5423 11.2495 51.3655 8.95271 49.0546C6.65591 46.7578 5.46498 43.5678 5.67764 40.3069C5.74853 39.2719 5.30902 38.1944 4.51506 37.4997C2.04813 35.3588 0.644531 32.2539 0.644531 28.993C0.644531 25.7321 2.06231 22.6414 4.51506 20.5005C5.30902 19.82 5.74853 18.7425 5.67764 17.6933C5.4508 14.4324 6.64173 11.2424 8.95271 8.94563C11.2495 6.64883 14.4679 5.44372 17.7004 5.67056C18.7354 5.72727 19.8129 5.30194 20.5076 4.50798C22.6484 2.04105 25.7534 0.637451 29.0001 0.637451C32.2468 0.637451 35.3517 2.05523 37.4926 4.50798C38.1731 5.30194 39.2506 5.74145 40.2998 5.67056C43.5465 5.44372 46.7507 6.63465 49.0475 8.94563C51.3443 11.2424 52.5352 14.4324 52.3225 17.6933C52.2516 18.7425 52.6912 19.8058 53.4851 20.5005C55.952 22.6414 57.3556 25.7463 57.3556 28.993C57.3556 32.2397 55.9379 35.3447 53.4851 37.4855C52.6912 38.166 52.2516 39.2435 52.3225 40.2927C52.5494 43.5536 51.3584 46.7436 49.0475 49.0404C46.7507 51.3372 43.5607 52.5281 40.2998 52.3154C39.2506 52.2446 38.1873 52.6841 37.4926 53.478C35.3517 55.945 32.2468 57.3486 29.0001 57.3486V57.3627ZM25.9841 13.3139H32.0172V33.8264H25.9841V13.3139ZM32.0172 38.6528H25.9841V44.6859H32.0172V38.6528Z"
          fill="#6400B9" />
      </svg>

      <div class="flex flex-col items-center justify-center">
        <p
          v-sanitize="t('existingPhoneTitle', { phone: form.phone })"
          class="hl-3-m md:hl-2-sb text-pine-600 text-24 mb-24 text-center" />

        <p class="body-2-m md:body-1-m text-pine-600 mb-24 text-center">
          {{ t('existingPhoneDescription') }}
        </p>

        <RoomieButton
          v-if="$config.public.loginOtpDisabled"
          :id="`${id}-existing-submit-button-no-otp`"
          class="w-full gap-8 font-medium"
          :data-lh-id="`${id}-existing-submit-button-no-otp`"
          type="submit"
          variant="primary"
          :aria-label="t('ctaNoOtp')"
          @click="sendCodeFromExistingCustomer">
          <span class="flex">
            {{ t('ctaNoOtp') }}
          </span>
        </RoomieButton>

        <RoomieButton
          v-else
          :id="`${id}-existing-submit-button`"
          class="w-full gap-8 font-medium"
          :data-lh-id="`${id}-existing-submit-button`"
          type="submit"
          variant="primary"
          :aria-label="t('cta')"
          @click="sendCodeFromExistingCustomer">
          <span class="flex">
            {{ t('existingPhoneCta') }}
          </span>
        </RoomieButton>

        <a
          id="login-form-go-back-cta"
          class="block text-pine-600 underline cursor-pointer text-center mt-16"
          data-lh-id="login-form-go-back-cta"
          @click="handleSignUpEvent">
          {{ t('otpGoBackCta') }}
        </a>
      </div>
    </div>

    <div
      v-if="formStage === 'TECHNICAL_ERROR'"
      id="technical-error-form"
      class="flex flex-col items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="58"
        height="88"
        viewBox="0 0 58 58"
        fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.0001 57.3627C25.7392 57.3627 22.6484 55.945 20.5076 53.4922C19.8271 52.6982 18.7496 52.2729 17.7004 52.3296C14.4395 52.5423 11.2495 51.3655 8.95271 49.0546C6.65591 46.7578 5.46498 43.5678 5.67764 40.3069C5.74853 39.2719 5.30902 38.1944 4.51506 37.4997C2.04813 35.3588 0.644531 32.2539 0.644531 28.993C0.644531 25.7321 2.06231 22.6414 4.51506 20.5005C5.30902 19.82 5.74853 18.7425 5.67764 17.6933C5.4508 14.4324 6.64173 11.2424 8.95271 8.94563C11.2495 6.64883 14.4679 5.44372 17.7004 5.67056C18.7354 5.72727 19.8129 5.30194 20.5076 4.50798C22.6484 2.04105 25.7534 0.637451 29.0001 0.637451C32.2468 0.637451 35.3517 2.05523 37.4926 4.50798C38.1731 5.30194 39.2506 5.74145 40.2998 5.67056C43.5465 5.44372 46.7507 6.63465 49.0475 8.94563C51.3443 11.2424 52.5352 14.4324 52.3225 17.6933C52.2516 18.7425 52.6912 19.8058 53.4851 20.5005C55.952 22.6414 57.3556 25.7463 57.3556 28.993C57.3556 32.2397 55.9379 35.3447 53.4851 37.4855C52.6912 38.166 52.2516 39.2435 52.3225 40.2927C52.5494 43.5536 51.3584 46.7436 49.0475 49.0404C46.7507 51.3372 43.5607 52.5281 40.2998 52.3154C39.2506 52.2446 38.1873 52.6841 37.4926 53.478C35.3517 55.945 32.2468 57.3486 29.0001 57.3486V57.3627ZM25.9841 13.3139H32.0172V33.8264H25.9841V13.3139ZM32.0172 38.6528H25.9841V44.6859H32.0172V38.6528Z"
          fill="#6400B9" />
      </svg>

      <div class="flex flex-col items-center justify-center">
        <p
          v-sanitize="t('technicalErrorTitle')"
          class="hl-2-sb md:display-6 text-pine-600 text-24 mb-24 text-center" />

        <p class="body-2-m md:body-1-m text-pine-600 mb-24 text-center">
          {{ t('technicalErrorDescription') }}
        </p>

        <RoomieButton
          :id="`${id}-error-start-button`"
          class="w-full gap-8 font-medium"
          :data-lh-id="`${id}-error-start-button`"
          type="submit"
          variant="primary"
          :aria-label="t('technicalErrorCta')"
          @click="formStage = 'PHONE_FORM'">
          <span class="flex">
            {{ t('technicalErrorCta') }}
          </span>
        </RoomieButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.login-form {
  .roomie-button.roomie-button--filled {
    &:disabled {
      @apply bg-carbon-light-50 text-carbon-light-600 opacity-50;

      border: 1px solid #919E9B;

      svg {
        @apply fill-carbon-light-600;
      }
    }
  }

  .roomie-otp-input__helper-text {
    margin-top: 0;
  }

  @media(max-width: theme('screens.sm')) {
    .roomie-otp-input__digit {
      width: 4.2rem;
      padding: 0.3rem;
    }
  }
}

.divider-text {
  @apply flex items-center w-full gap-16;

  p {
    @apply whitespace-nowrap;
  }

  &::before,
  &::after {
    @apply inline-block bg-carbon-light-300;

    content: '';
    width: 100%;
    height: 1px;
  }
}
</style>

<i18n src="./i18n.json" lang="json"></i18n>
