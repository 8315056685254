<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<script lang="ts" setup>

import { reactive, computed, ref, watch } from 'vue'
import RoomieButtonComponent from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import RoomieInputField, { STATUS as INPUT_STATUS } from '@lahaus-roomie/roomie/src/components/RoomieInput/index.vue'
import RoomiePhoneInputField from '@lahaus-roomie/roomie/src/components/RoomiePhoneInput/index.vue'
import RoomieLoader from '@lahaus-roomie/roomie/src/components/RoomieLoader/index.vue'
import UnlockIconImage from '@lahaus-roomie/roomie/src/assets/icons/unlock.svg'

import type { Props, PhoneProperties } from './types'
import { validateEmail } from '@/utils/validations/emailValidation'
import { LISTING_SOURCE, TYPE_ATTENTION, SCREEN_CTA } from '@/utils/segment'
import type { LeadCreationRequestedTraits } from '@/utils/segment/types'
import { useContactFormStore } from '@/stores/contactFormStore'
import LegalPolicies from '@/components/LegalPolicies/index.vue'
import { useThemeStore } from '@/stores/useThemeStore'

const themeStore = useThemeStore()
const { $cdpDataService } = useNuxtApp()
const { t, locale } = useI18n()

const emit = defineEmits(['submit', 'sign-in'])

const showWarningLegalPolicy = ref(false)
const legalPoliciesAccepted = ref(false)
const status = ref('form')
const externalError = ref(null)

const props = withDefaults(defineProps<Props>(), {
  listingIds: () => [],
  listingId: '',
  screen: '',
  totalSavings: 0,
  showEmailInput: true,
  showNameInput: true,
  showCtaIcon: false,
  attentionType: TYPE_ATTENTION.PRODUCT_BOT,
  showGeneralTitle: false,
  shouldEnqueueTwilioContact: true
})

const opportunityTypeEventMap: Record<string, string> = {
  living: 'LIVING',
  investing: 'INVESTMENT'
}

const contactFormStore = useContactFormStore()
const form = reactive({
  phone_is_valid: false,
  phone_prefix: '',
  phone_number: '',
  phone: contactFormStore.userPhone,
  email: contactFormStore.userEmail,
  name: contactFormStore.userName
})

const onboardingBedroomsNumber = useCookie<number>('onboarding_bedrooms_number')
const onboardingOpportunityType = useCookie<string>('onboarding_opportunity_type')

const title = computed(() => props.showGeneralTitle ? t('titleGeneral') : t('title'))

watch(() => contactFormStore.userPhone, () => {
  form.phone = contactFormStore.userPhone
  form.name = contactFormStore.userName
  form.email = contactFormStore.userEmail
})

const emailValidated = ref(false)

const emailStatus = computed(() => {
  if (!emailValidated.value) return INPUT_STATUS.default

  return validateEmail(form.email) ? INPUT_STATUS.success : INPUT_STATUS.error
})

const emailHelperText = computed(() => {
  if (emailStatus.value === INPUT_STATUS.success) return ''

  if (emailStatus.value === INPUT_STATUS.error) return form.email ? t('invalidEmail') : t('requiredEmail')

  return ''
})

const handleFormSubmit = async () => {
  emailValidated.value = true

  if (!legalPoliciesAccepted.value) {
    showWarningLegalPolicy.value = true
    return
  }

  showWarningLegalPolicy.value = false

  if (props.showEmailInput && emailStatus.value === INPUT_STATUS.error) return

  const isPhoneValid = form.phone_is_valid

  if (isPhoneValid) {
    const customer = {
      email: form.email,
      phone: form.phone,
      name: form.name
    }

    let response = null
    try {
      status.value = 'loader'
      response = await $cdpDataService.createCustomer(customer)
      status.value = 'form'
    } catch (error: any) {
      status.value = 'form'
      const errorData = error?.data
      const errorResponse = error?.response

      if (errorData?.code === 'customer_already_exist') {
        contactFormStore.updateUserName(form.name)
        contactFormStore.updateUserEmail(form.email)
        contactFormStore.updateUserPhone(form.phone, false)
        contactFormStore.updateLegalConsent(legalPoliciesAccepted.value)

        emit('sign-in', 'EXISTING_CUSTOMER_PHONE')

        return false
      } else if (errorData?.code === 'error_invalid_phone') {
        externalError.value = 'Revisa el país o el número ingresado'

        return false
      } else if (errorData?.code === 'error_spam_phone') {
        externalError.value = 'El número ingresado ha sido bloqueado por nuestro equipo. Si consideras que esto es un error, por favor contáctanos a servicioalcliente@lahaus.com para recibir asistencia.'

        sendSlackAlert('Registration Form | Lead clasificado como SPAM intentó registrarse', { error })

        return false
      } else {
        status.value = 'technical_error'
        let alertContent = 'Registration Form | No fue posible crear el usuario en CDP'
        alertContent += `\n${JSON.stringify(error)}`
        alertContent += `\n${JSON.stringify(errorData)}`
        alertContent += `\n${JSON.stringify(errorResponse)}`
        sendSlackAlert(alertContent, { error })

        return false
      }
    }

    if (response?.id) {
      contactFormStore.updateUserName(form.name)
      contactFormStore.updateUserEmail(form.email)
      contactFormStore.updateUserPhone(form.phone, false)
      contactFormStore.updateLegalConsent(legalPoliciesAccepted.value)

      await trackLeadCreationRequest()

      emit('submit', form, response?.id)
    } else {
      sendSlackAlert('Registration Form | Usuario se registró pero no se pudo obtener el user id en el response del createCustomer', {
        ...response
      })
    }
  }
}

const handleSignInEvent = () => {
  emit('sign-in')
}

const { $trackLead } = useNuxtApp()

const trackLeadCreationRequest = async () => {
  const leadPayload = getLCRProperties()
  await $trackLead({
    phone: leadPayload.phone,
    listings: [
      leadPayload.listing_id
    ],
    screen: leadPayload.screen,
    screenCta: leadPayload.screen_cta,
    properties: leadPayload
  })

  // @ts-ignore
  window.dataLayer?.push({
    event: 'user-registration-requested',
    phone: leadPayload.phone,
    screen: leadPayload.screen,
    screen_cta: leadPayload.screen_cta
  })
}

const sendSlackAlert = (title: string, extraPayload = {}) => {
  $fetch('/api/buyer-front/send-slack-alert', {
    method: 'POST',
    body: {
      title,
      content: {
        userName: form.name,
        userEmail: form.email,
        userPhone: form.phone,
        url: window.location.href,
        ...extraPayload
      }
    }
  })
}

const roomieButtonDisabled = computed(() => {
  if (showWarningLegalPolicy.value) return true

  return !legalPoliciesAccepted.value
})

const getPhoneProperties = (value:PhoneProperties) => {
  form.phone_number = value.phoneNumber
  form.phone_prefix = value.code
}

const getLCRProperties = () => {
  const data = {
    phone: form.phone,
    phone_number: form.phone_number,
    phone_prefix: form.phone_prefix,
    screen: props.screen,
    screen_cta: SCREEN_CTA.INVESTMENT_REGISTRATION_FORM,
    location_of_interest_codes: [locale.value.toUpperCase()],
    budget_currency: t('currency'),
    type_attention: props.attentionType,
    purchase_purpose: onboardingOpportunityType.value && opportunityTypeEventMap[onboardingOpportunityType.value] ? opportunityTypeEventMap[onboardingOpportunityType.value] : 'INVESTMENT_AND_LIVING'
  } as LeadCreationRequestedTraits

  if (form.name) {
    data.name = form.name
  }

  if (form.email) {
    data.email = form.email.toLowerCase()
  }

  if (props.listingId) {
    data.listing_id = props.listingId
    data.listing_source = LISTING_SOURCE.PROPERTY_CATALOG
  } else if (props.listingIds.length) {
    data.listing_id = props.listingIds[0]
    data.listing_source = LISTING_SOURCE.PROPERTY_CATALOG
  }

  if (props.hubCode) {
    data.business_hub_code = props.hubCode
  }

  if (props.totalSavings) {
    data.down_payment_amount = props.totalSavings
  }

  if (props.monthlyPayments !== null) {
    data.monthly_payment_budget_max = props.monthlyPayments
  }

  if (onboardingBedroomsNumber.value) {
    data.bedrooms = onboardingBedroomsNumber.value
  }

  return data
}

const handlePhoneInputEvent = (newValue: string) => {
  form.phone = newValue
  externalError.value = null
}

</script>

<script lang="ts">
export default {
  name: 'SyncRegisterForm'
}
</script>

<i18n src="./i18n.json" lang="json"></i18n>

<template>
  <div class="register-form">
    <div
      v-if="status==='form'">
      <h2
        v-sanitize.basic="title"
        class="hl-3-sb md:hl-2-sb my-8 text-pine-600" />

      <form
        class="mt-24 flex flex-col gap-y-16"
        @submit.prevent="handleFormSubmit">
        <RoomieInputField
          v-if="showNameInput"
          id="contact-form-name"
          v-model="form.name"
          :is-internal-validate="false"
          placeholder="Escríbe tu nombre completo"
          :required="true" />

        <RoomieInputField
          v-if="showEmailInput"
          id="contact-form-email"
          v-model="form.email"
          :helper-text="emailHelperText"
          :is-internal-validate="false"
          :status="emailStatus"
          placeholder="Tu correo"
          :required="true"
          @blur="emailValidated = true" />

        <RoomiePhoneInputField
          id="lead-form-phone"
          class="-mt-20"
          :value="form.phone"
          :is-required="true"
          :initial-country="locale"
          name="lead-form-phone"
          :external-error="externalError"
          @full="(value: PhoneProperties) => getPhoneProperties(value)"
          @is-valid="(isValid: boolean) => form.phone_is_valid = isValid"
          @input="handlePhoneInputEvent" />

        <LegalPolicies
          v-model="legalPoliciesAccepted"
          class="!mb-0"
          :screen-cta="SCREEN_CTA.INVESTMENT_REGISTRATION_FORM"
          :screen="screen"
          :show-warning="showWarningLegalPolicy"
          :phone="form.phone" />

        <RoomieButtonComponent
          :id="`${id}-submit-button`"
          :disabled="roomieButtonDisabled"
          :aria-label="t('cta')"
          type="submit"
          variant="primary"
          :data-lh-id="`${id}-submit-button`"
          class="w-full gap-8 font-medium">
          <span class="flex">
            <UnlockIconImage
              v-if="!themeStore.isV2"
              class="h-24 w-24 fill-white mr-4" />
            {{ t('cta') }}
          </span>
        </RoomieButtonComponent>
      </form>

      <div class="flex flex-col mt-24 gap-8">
        <p
          :class="{
            'body-1-sb text-pine-600': themeStore.isV2,
            'text-carbon-800 font-medium': !themeStore.isV2
          }"
          class="text-center">
          {{ t('signInQuestion') }}

          <a
            :id="`${id}-authentication-button`"
            :class="{
              'body-2-sb text-pine-600': themeStore.isV2,
              'font-medium text-primary-800': !themeStore.isV2
            }"
            class="ml-8 gap-8 underline"
            href="#"
            :data-lh-id="`${id}-authentication-button`"
            :aria-label="t('signIn')"
            @click="handleSignInEvent">
            {{ t('signIn') }}
          </a>
        </p>
      </div>
    </div>

    <div
      v-else-if="status === 'technical_error'"
      id="technical-error-form"
      class="h-[314px] flex flex-col items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="58"
        height="88"
        viewBox="0 0 58 58"
        fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M29.0001 57.3627C25.7392 57.3627 22.6484 55.945 20.5076 53.4922C19.8271 52.6982 18.7496 52.2729 17.7004 52.3296C14.4395 52.5423 11.2495 51.3655 8.95271 49.0546C6.65591 46.7578 5.46498 43.5678 5.67764 40.3069C5.74853 39.2719 5.30902 38.1944 4.51506 37.4997C2.04813 35.3588 0.644531 32.2539 0.644531 28.993C0.644531 25.7321 2.06231 22.6414 4.51506 20.5005C5.30902 19.82 5.74853 18.7425 5.67764 17.6933C5.4508 14.4324 6.64173 11.2424 8.95271 8.94563C11.2495 6.64883 14.4679 5.44372 17.7004 5.67056C18.7354 5.72727 19.8129 5.30194 20.5076 4.50798C22.6484 2.04105 25.7534 0.637451 29.0001 0.637451C32.2468 0.637451 35.3517 2.05523 37.4926 4.50798C38.1731 5.30194 39.2506 5.74145 40.2998 5.67056C43.5465 5.44372 46.7507 6.63465 49.0475 8.94563C51.3443 11.2424 52.5352 14.4324 52.3225 17.6933C52.2516 18.7425 52.6912 19.8058 53.4851 20.5005C55.952 22.6414 57.3556 25.7463 57.3556 28.993C57.3556 32.2397 55.9379 35.3447 53.4851 37.4855C52.6912 38.166 52.2516 39.2435 52.3225 40.2927C52.5494 43.5536 51.3584 46.7436 49.0475 49.0404C46.7507 51.3372 43.5607 52.5281 40.2998 52.3154C39.2506 52.2446 38.1873 52.6841 37.4926 53.478C35.3517 55.945 32.2468 57.3486 29.0001 57.3486V57.3627ZM25.9841 13.3139H32.0172V33.8264H25.9841V13.3139ZM32.0172 38.6528H25.9841V44.6859H32.0172V38.6528Z"
          fill="#6400B9" />
      </svg>

      <div class="flex flex-col items-center justify-center">
        <p
          v-sanitize="t('technicalErrorTitle')"
          class="hl-2-sb md:display-6 text-pine-600 text-24 mb-24 text-center" />

        <p class="body-2-m md:body-1-m text-pine-600 mb-24 text-center">
          {{ t('technicalErrorDescription') }}
        </p>

        <RoomieButtonComponent
          :id="`${id}-error-start-button`"
          class="w-full gap-8 font-medium"
          :data-lh-id="`${id}-error-start-button`"
          type="submit"
          variant="primary"
          :aria-label="t('technicalErrorCta')"
          @click="status = 'form'">
          <span class="flex">
            {{ t('technicalErrorCta') }}
          </span>
        </RoomieButtonComponent>
      </div>
    </div>

    <div
      v-else
      class="h-[337px] flex items-center justify-center">
      <RoomieLoader
        size="xl"
        color="primary" />
    </div>
  </div>
</template>

<style lang="scss">
.lock-overlay__registration-modal {
  .roomie-modal__body {
    @apply mt-16;
  }
}

.register-form {
  .roomie-button.roomie-button--filled {
    &:disabled {
      @apply bg-carbon-light-50 text-carbon-light-600 opacity-50;

      border: 1px solid #919E9B;

      svg {
        @apply fill-carbon-light-600;
      }
    }
  }
}

.divider-text {
  @apply flex items-center w-full gap-16;

  p {
    @apply whitespace-nowrap;
  }

  &::before,
  &::after {
    @apply inline-block bg-carbon-light-300;

    content: '';
    width: 100%;
    height: 1px;
  }
}
</style>
