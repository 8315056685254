<script setup lang="ts">
import { onMounted } from 'vue'
import ReferralBanner from '@/components/Referrals/Banner/index.vue'
import type { ReferralBannerHandlerComponentModule } from '@/components/Referrals/BannerHandler/types'
const { t } = useI18n()
const props = defineProps<ReferralBannerHandlerComponentModule.Props>()

const { experimentVersion, initExperiment } = useABTest(props.abTestingKey)

onMounted(() => {
  initExperiment()
})
</script>

<script lang="ts">
export default {
  name: 'ReferralBannerHandler'
}
</script>

<template>
  <ReferralBanner
    :id="abTestingKey"
    :title="t(`${experimentVersion}.title.part1`)"
    :subtitle="t(`${experimentVersion}.title.part2`)"
    :subtitle-2="t(`${experimentVersion}.title.part3`)"
    :description="t(`${experimentVersion}.description`)"
    :submit-button-text="t(`${experimentVersion}.registerNow`)"
    :submit-button-url="`${t(`${experimentVersion}.registerUrl`)}?utm_source=${sourcePage}&utm_medium=web`" />
</template>

<i18n src="./i18n.json" type="json" />
