<script setup lang="ts">
import RoomieButtonLink from '@lahaus-roomie/roomie/src/components/RoomieButtonLink/index.vue'
import type { ReferralBannerComponentModule } from '@/components/Referrals/Banner/types'
import { useThemeStore } from '@/stores/useThemeStore'

const themeStore = useThemeStore()

defineProps<ReferralBannerComponentModule.Props>()

defineEmits(['submit-form'])
</script>

<script lang="ts">
export default {
  name: 'ReferralBanner'
}
</script>

<template>
  <div class="flex flex-col gap-24 items-start md:items-center md:flex-row p-lh-24 md:p-lh-32 justify-between rounded-[2rem] referral-banner">
    <div v-if="themeStore.isV2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="99"
        height="46"
        viewBox="0 0 99 46"
        fill="none">
        <path
          d="M76.1586 43.2902C75.547 44.1732 76.179 45.3798 77.2532 45.3798L81.81 45.3798C82.2443 45.3799 82.6514 45.168 82.9006 44.8122L97.6428 23.7634C97.9639 23.3048 97.9639 22.6943 97.6428 22.2357L82.9006 1.18692C82.6514 0.831144 82.2444 0.619273 81.81 0.619266L77.2533 0.619187C76.1791 0.619168 75.5471 1.82571 76.1587 2.70878L89.6869 22.2413C90.0028 22.6974 90.0028 23.3015 89.6869 23.7575L76.1586 43.2902Z"
          fill="#E6FA41" />

        <path
          d="M1.44964 43.2892C0.83728 44.1722 1.4692 45.3795 2.54374 45.3795L32.1094 45.3798C32.5434 45.3798 32.9503 45.1683 33.1995 44.8129L47.9609 23.7641C48.2827 23.3053 48.2827 22.694 47.9609 22.2351L33.1995 1.18644C32.9503 0.831053 32.5435 0.619464 32.1094 0.61946L2.5438 0.61917C1.46925 0.619159 0.837307 1.82644 1.44967 2.70944L14.9947 22.2405C15.3112 22.6969 15.3112 23.3017 14.9947 23.7581L1.44964 43.2892Z"
          fill="#2DE187" />

        <path
          d="M38.8051 43.2892C38.1927 44.1722 38.8247 45.3795 39.8992 45.3795L69.4648 45.3798C69.8989 45.3798 70.3057 45.1683 70.555 44.8129L85.3164 23.7641C85.6382 23.3053 85.6382 22.694 85.3164 22.2351L70.555 1.18644C70.3057 0.831053 69.8989 0.619464 69.4649 0.61946L39.8993 0.61917C38.8247 0.619159 38.1928 1.82644 38.8051 2.70944L52.3501 22.2405C52.6666 22.6969 52.6666 23.3017 52.3501 23.7581L38.8051 43.2892Z"
          fill="white" />
      </svg>
    </div>

    <div
      :class="{
        'flex-1 md:ml-24': themeStore.isV2
      }"
      class="w-full md:w-auto">
      <h2
        :class="{
          'hl-2-sb md:hl-1-sb': themeStore.isV2,
          'text-24 md:text-38': !themeStore.isV2
        }"
        class="text-white">
        {{ title }}
        <span
          :class="{
            'text-lime-600': themeStore.isV2,
            'text-brand-600 font-semibold': !themeStore.isV2
          }">
          {{ subtitle }}
        </span>
        {{ subtitle2 }}
      </h2>

      <p
        :class="{
          'hl-2-m': themeStore.isV2,
          'text-14 md:text-16 font-medium': !themeStore.isV2
        }"
        class="text-white">
        {{ description }}
      </p>
    </div>

    <div class="w-full md:w-auto mt-lh-24 md:m-0">
      <RoomieButtonLink
        :id="`${id}-button-register-now`"
        :data-lh-id="`${id}-button-register-now`"
        :class="{
          '!bg-spring-600 hover:bg-spring-500 !px-32 !py-16 !text-pine-600 !rounded-xxl !inline': themeStore.isV2,
          '!bg-white hover:bg-primary-50': !themeStore.isV2
        }"
        class="w-full"
        variant="outlined"
        target-type="_blank"
        :url="submitButtonUrl">
        {{ submitButtonText }}
      </RoomieButtonLink>
    </div>
  </div>
</template>

<style lang="scss">
.referral-banner {
  background: radial-gradient(42.71% 50.67% at 3.06% 4.43%, rgb(31 121 101 / 92%) 0%, rgb(21 24 24 / 92%) 100%);
}

.lh-roomie-v2 {
  .referral-banner {
    background: none;

    @apply bg-pine-600;
  }
}
</style>
